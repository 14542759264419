import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import loadable from '@loadable/component'
import toast from 'cogo-toast';
import { 
    showMessage, 
    handleHTTPError,
    setInit,
    setPatientGroups,
    setSupportTeam
} from 'store/actions';
import { getCounty } from 'apis';

const Login =  loadable(()=>import('./public_routes/login'))
const Register =  loadable(()=>import('./public_routes/register'))
const PrivateRoutes =  loadable(()=>import('./private_routes'))

const RestrictedRoute = ({component: Component, user, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            user
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: props.vacLocation}
                    }}
                />}
    />;

class Routes extends React.Component {

    componentDidMount = () => {

        getCounty().then(data => {

            this.props.setInit(data)

        }).catch(error => {

            this.props.handleHTTPError(error)
        })
    }

    componentDidUpdate = (prevProps) => {

		if (!prevProps.showMsg && this.props.showMsg) {

			if (this.props.message !== null) {

				if (this.props.message.type === 'success') {

					return toast.success(this.props.message.text, { position: 'top-right', heading: 'Success' }).then(() => { 

                        this.props.showMessage({ showMsg: false, message: null}) 
                    })

				}else if (this.props.message.type === 'warning') {

					return toast.warn(this.props.message.text, { position: 'top-right', heading: 'Warning' }).then(() => { 
                        
                        this.props.showMessage({ showMsg: false, message: null}) 
                    })

				}else {

					return toast.error(this.props.message.text, { position: 'top-right', heading: 'Error' }).then(() => { 
                        
                        this.props.showMessage({ showMsg: false, message: null}) 
                    })
				}
			}
		}
	}

    render() {

        if (!this.props.county) return null;

        const {match, user} = this.props;
        
        return (
           <Switch>
               <Route path='/login' component={Login}/>
               <Route path='/register' component={Register}/>
               <RestrictedRoute path={`${match.url}`} user={user} component={PrivateRoutes}/>
           </Switch>
        );
    }
}


const mapStateToProps = (reducer) => {

    const {county, user, showMsg, message } = reducer;

    return {county, user, showMsg, message };
};

export default connect(mapStateToProps, { 
    
    showMessage, 

    setInit,

    handleHTTPError,

    setPatientGroups,

    setSupportTeam,

})(Routes);