export default {

    BASE_URL: `${process.env.REACT_APP_SERVER_URL}/api`,

    // county

    GET_COUNTY_URL: 'county/get',

    // user
    PATIENT_URL: 'patients',
    PATIENT_LOGIN_URL: 'patients/login',
    PATIENT_RESET_ACCESSCODE_URL: 'patients/reset-accesscode',
    PATIENT_REGISTER_URL: 'patients/register',

    COUNTY_SETTING_URL: 'county-settings',
    COUNTY_REMOVE_ZIPCODE_URL: 'county-settings/delete-zipcode',

    LOCATION_URL: 'vacLocations',

    LOCATION_ANALYSYS_URL: 'vacLocations/analysis',

    LOCATION_SETTING_URL: 'vacLocation-settings',

    REQTEMPLATE_URL: 'reqtemplates',

    REQUEST_URL: 'requests',
    REQUEST_WAITLIST_URL: 'requests/waitlist',

    SCHEDULE_URL: 'schedules',
    SCHEDULE_PUBLISH_URL: 'schedules/publish',
    SCHEDULE_ISVALIDPATIENT_URL: 'schedules/is-valid-patient',

    PLAN_URL: 'plans',

    POST_URL: 'posts',

    COMMENT_URL: 'comments'
}

