import axios from 'axios';
import API_URL from './api_url'


export const retrieveReqTemplates = (params) => {
    
    return axios.get(`${API_URL.REQTEMPLATE_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const readReqTemplate = (params) => {
    
    return axios.get(`${API_URL.REQTEMPLATE_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};