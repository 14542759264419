import {
    SET_EMERGENCY_ERRORS,
    HTTP_401_ERROR,
	HTTP_OTHER_ERROR,
    SHOW_MESSAGE,
    TOGGLE_SIDEBAR,
    WINDOW_WIDTH,
    SET_USER,
    LOGOUT,
    SET_COUNTY,
    SET_INIT,
	SET_PATIENT_GROUPS,
	SET_PATIENT_SEARCHPARAMS,
    SET_LOCATION_SETTINGS,
	SET_LOCATION,
    SET_SUPPORTEAM,
} from '../actionTypes';


/** emergency errors */

export const setEmergencyErrors = (error) => {

	return {
		type: SET_EMERGENCY_ERRORS,
		payload: error
	};
}

/** http error  */

export const excute401ErrorHandler = (error) => {

	return {
		type: HTTP_401_ERROR,
		payload: error
	};
}


export const excuteOtherErrorHandler = (error) => {

	return {
		type: HTTP_OTHER_ERROR,
		payload: error
	};
}


export const handleHTTPError = (error) => {

    if (error.status === 401)
    
        return excute401ErrorHandler(error)

	else
		return excuteOtherErrorHandler(error)
}


/** UI */
export const toggleSidebar = (data) => {

    return {
        type: TOGGLE_SIDEBAR, 
        payload: data
    };
};

export const setWindowWidth = (width) => {
	
    return {
        type: WINDOW_WIDTH,
        payload: width
    };
}


export const showMessage = (data) => {

    return {
        type: SHOW_MESSAGE,
        payload: data
    };
};


/** county */
export const setInit = (data) => {
    return {
        type: SET_INIT,
        payload: data,
    };
};

export const setCounty = (data) => {
    return {
        type: SET_COUNTY,
        payload: data,
    };
};

/** set support team */
export const setSupportTeam = (data) => {
    return {
        type: SET_SUPPORTEAM,
        payload: data,
    };
};


/** user */
export const setUser = (data) => {
    return {
        type: SET_USER,
        payload: data,
    };
};

export const logout = () => {
    return {
        type: LOGOUT,
    };
};

export const setPatientGroups = (payload) => {

    return {
        type: SET_PATIENT_GROUPS,
        payload: payload
    };
};

export const setPatientSearchParams = (payload) => {

    return {
        type: SET_PATIENT_SEARCHPARAMS,
        payload: payload
    };
};

/** venu */

export const setLocation = (data) => {

    return {
        type: SET_LOCATION,
        payload: data,
    };
};

export const setLocationSetting = (data) => {
    return {
        type: SET_LOCATION_SETTINGS,
        payload: data 
    };
};


