import axios from './axios';
import API_URL from './api_url'

export const createPatient = (params) => {
    
    return axios.post(`${API_URL.PATIENT_REGISTER_URL}`, params )
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const login = (params) => {
    
    return axios.post(`${API_URL.PATIENT_LOGIN_URL}`, params )
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const readPatient = (params) => {

    return axios.get(`${API_URL.PATIENT_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const resetAccessCode = (params) => {

    return axios.post(`${API_URL.PATIENT_RESET_ACCESSCODE_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const updatePatient = (params) => {

    return axios.patch(`${API_URL.PATIENT_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const retrievePatients = (params) => {
    
    return axios.get(`${API_URL.PATIENT_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};