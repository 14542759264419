export const SET_EMERGENCY_ERRORS = 'set_emergency_errors'


// http error

export const HTTP_401_ERROR = 'http_unauthorized_error'

export const HTTP_OTHER_ERROR = 'http_other_error'


// Ui 
export const SHOW_MESSAGE = 'show_message';

export const TOGGLE_SIDEBAR = 'toggle_sidebar'

export const WINDOW_WIDTH = 'window_width';


// 

export const SET_INIT = 'set_init'

export const SET_COUNTY = 'set_county'

export const SET_SUPPORTEAM = 'set_support_team'

export const SET_USER = 'set_user'

export const LOGOUT = 'logout'

export const SET_PATIENT_GROUPS = 'set_patientgrups'

export const SET_LOCATION_SETTINGS = 'set_vacLocation_settings'

export const SET_PATIENT_SEARCHPARAMS = 'set_patient_searchparams'

export const SET_REQTPENDDATE = 'set_reqtemplate_enddate'

export const SET_DATETIMESETTINGS = 'set_reqtemplate_datetimesetting'

export const SET_LOCATION = 'set_vacLocation'