import axios from 'axios';
import API_URL from './api_url'
import store from 'store';

axios.interceptors.request.use(function (config) {

	config.baseURL = API_URL.BASE_URL;
	config.timeout = 30000;
	
    const token = store.get('token');
    
    config.headers.Authorization =  token ? `Bearer ${token}` : '';

	return config;
});


axios.interceptors.response.use(function (response) {

    return response;

}, function (error) {

    if (!error.response) return Promise.reject({
		response: {
			status: 408,
			data: {
				message: 'Request Timeout'
			}
		}
	})

	return Promise.reject(error);
});

export default axios;