import axios from './axios';
import API_URL from './api_url'

export const createRequest = (params) => {
    
    return axios.post(`${API_URL.REQUEST_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const updateRequest = (params) => {
    
    return axios.patch(`${API_URL.REQUEST_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const readRequest = (params) => {
    
    return axios.get(`${API_URL.REQUEST_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const retrieveRequests = (params) => {
    
    return axios.get(`${API_URL.REQUEST_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const deleteRequest = (params) => {
    
    return axios.delete(`${API_URL.REQUEST_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const findWaitList = (params) => {
    
    return axios.get(`${API_URL.REQUEST_WAITLIST_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

