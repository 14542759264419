import axios from './axios';
import API_URL from './api_url'

export const createPost = (params) => {
    
    return axios.post(`${API_URL.POST_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const updatePost = (params) => {
    
    return axios.patch(`${API_URL.POST_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const readPost = (params) => {
    
    return axios.get(`${API_URL.POST_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const retrievePosts = (params) => {
    
    return axios.get(`${API_URL.POST_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const deletePost = (params) => {
    
    return axios.delete(`${API_URL.POST_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};