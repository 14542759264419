import {
    SET_EMERGENCY_ERRORS,
    HTTP_401_ERROR,
	HTTP_OTHER_ERROR,
    SHOW_MESSAGE,
    TOGGLE_SIDEBAR,
    WINDOW_WIDTH,
    SET_COUNTY,
    SET_INIT,
    SET_USER,
    SET_PATIENT_SEARCHPARAMS,
	LOGOUT,
	SET_PATIENT_GROUPS,
	SET_LOCATION_SETTINGS,
    SET_LOCATION,
    SET_DATETIMESETTINGS,
    SET_SUPPORTEAM
} from '../actionTypes';

import store from 'store'

const INIT_STATE = {

    emergencyErrors: [],

    /** ui */
    openSidebar: false,
    width: window.innerWidth,
    message: null,
    showMsg: false,

    /** county */
    county: null,

    /** support teams */
    supportTeams:[],
    /** user */
    user: store.get('user') || null,
    patientGroups: [],
    patientSearchParams: null,

    /** vacLocation */
    vacLocation: store.get('vacLocation') || null,
    vacLocationSetting: store.get('vacLocationSetting') || null,

    /** template */
    dateTimeSettings: store.get('dateTimeSettings'),
};



export default (state = INIT_STATE, action) => {

    switch (action.type) {

        /** emergency errors */
        case SET_EMERGENCY_ERRORS:
			
            return { ...state, emergencyErrors: action.payload };

        /** http error */
        case HTTP_401_ERROR:

			store.clearAll();
            window.location.href = "/signin"
            return { ...state };

		case HTTP_OTHER_ERROR:

            return { ...state, showMsg: true, 
                
                message: { type: 'error', text: action.payload.data ? action.payload.data.message || 'Unknow Error' : String(action.payload)}
            }        

        /** UI */
		case SHOW_MESSAGE:

            return { ...state, showMsg: action.payload.showMsg, message: action.payload.message}

        case TOGGLE_SIDEBAR:

            return { ...state, openSidebar: action.payload };

        case WINDOW_WIDTH: 

            return { ...state, width: action.payload };

        
        /** county */
        case SET_COUNTY: 
        
            return { ...state, county: action.payload }

        case SET_INIT: 
        
            return { ...state, 
                
                county: action.payload.data, 
                
                patientGroups: action.payload.patientGroups,

                supportTeam: action.payload.supportTeam
            }

        case SET_SUPPORTEAM: 
        
            return { ...state, supportTeams: action.payload}

        /** user */
        case LOGOUT: 

            store.clearAll()
            return { ...state, user: null }

        case SET_USER: 

            store.set('user', action.payload)
            return { ...state, user: action.payload }

        case SET_PATIENT_SEARCHPARAMS: 

            return { ...state, patientSearchParams: action.payload }

        case SET_PATIENT_GROUPS: 

            return { ...state, patientGroups: action.payload}
        
        case SET_DATETIMESETTINGS: 

            return { ...state, dateTimeSettings: action.payload }

        case SET_LOCATION: 

            store.set('vacLocation', action.payload)
            return { ...state, vacLocation: action.payload}

        case SET_LOCATION_SETTINGS: 

            store.set('vacLocationSetting', action.payload)
            return { ...state, vacLocationSetting: action.payload }
        
        default:

            return state;
    }
}
